require('./bootstrap');

$("#Mensaje").toast({ autohide: true, delay: 2000 });
$('#Mensaje').on('hidden.bs.toast', function () {
    $('#Mensaje').css('z-index','-100');
})
  $('#Mensaje').on('show.bs.toast', function () {
    $('#Mensaje').css('z-index','9999');
})


$("#Mensaje2").toast({ autohide: false });
$('#Mensaje2').on('hidden.bs.toast', function () {
    $('#Mensaje2').css('z-index','-100');
})
  $('#Mensaje2').on('show.bs.toast', function () {
    $('#Mensaje2').css('z-index','9999');
})

$("#down-list").on('click', function() {
    $('#Mensaje2').toast('dispose');
});

//prevent more actions in forms
$('form').on('submit',function(){
    $('.btn').prop('disabled', true);
});



//REPORTES ----------------------------------
if(jQuery(location).attr('pathname').indexOf('reportes/') > -1 || jQuery(location).attr('pathname').indexOf('valida/') > -1 || jQuery(location).attr('pathname').indexOf('listado/ventas') > -1 )
  {


  $( function() {
    $( ".date" ).datepicker();
    $( ".date" ).datepicker("option", "showAnim","slideDown" );
    $( ".date" ).datepicker("option", "dateFormat", "dd-mm-yy" );
    $.ajaxSetup({ headers: {
      'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
    } });
  });


  $( function() {
      $("#tipo").on('change', function() {
          if ($(this).val() === "Resumen" || $(this).val() === "Ventas" || $(this).val() === "Transf") {
              $("#date").prop("disabled", false);
          } else {
              $("#date").prop("disabled", true);
          }
      });
  });

  $( function() {
    $.ajaxSetup({ headers: { 'csrftoken' : '{{ csrf_token() }}' } });
    $('#detalle').on('keyup',function()
    {
        $value=$(this).val();
            $.ajax({
                type : 'get',
                url : '/valida',
                data:{'id':$value},
                success:function(data)
                {
                    $('#res').html(data);

                    if ($('#res').text() === "VENTA ENCONTRADA")
                    {
                        $("#bdetalle").prop("disabled",false);
                    }
                    else
                    {
                        $("#bdetalle").prop("disabled",true);
                    }
                }
            });
        });
    });

  }
//FIN REPORTES -----------------------------------------------------------------------

//COMPRAS
  if(jQuery(location).attr('pathname').indexOf('prodcompra/') > -1 || jQuery(location).attr('pathname').indexOf('validart/') > -1)
  {


  $(function (){
    $('#articulos_id').trigger('focus');
  });

 //Al cargar, si tiene numero de serie
  $('.cargaprod').on('click', function (){
    if($('#articulos_id').length > 0)
    {
      if ($('#res').text() === 'NO REQUIERE SERIAL')
      {
        $('#cargoproducto').trigger('submit');
        return true;
      }
      else if($('#res').text() === 'REQUIERE SERIAL')
      {

          $('#cantidad').val('1');
          $('#sucursal').val($("#sucursal_id option:selected").val());
          $('#artref').val($('#articulos_id').val());
          $('#Modalserie').modal({backdrop: 'static', keyboard: false});
          $('#Modalserie').modal('show');

        return false;
      }
    }

  });

  //Valido el numero de articulo si lleva serial
  $( function() {
    $.ajaxSetup({ headers: { 'csrftoken' : '{{ csrf_token() }}' } });
    $('#articulos_id').on('focus change keyup focusout',function()
    {
        $value=$(this).val();
            $.ajax({
                type : 'get',
                url : '/validart',
                data:{'id':$value},

                success:function(data)
                {
                  if(data === 'NOSERIAL')
                  {
                    $('#res').html('<span class="text-light mb-2 mt-2 bg-danger">NO REQUIERE SERIAL</span>');
                    $('.cargaprod').prop('disabled',false);
                  }
                  else if(data === 'NO')
                  {
                    $('#res').html('<span class="text-light mb-2 mt-2 bg-danger">NO EXISTE EL ARTICULO</span>');
                  }
                  else if(data === 'SERIAL')
                  {
                    $('#res').html('<span class="text-light mb-2 mt-2 bg-success">REQUIERE SERIAL</span>');
                    $('.cargaprod').prop('disabled',false);
                  }

                },
                error:( function() {

                  $('#res').html('<span class="text-light mb-2 mt-2 bg-danger">ERROR DE ENVIO</span>');

              })

            });
        });
    });


     //Grabo el numero de serial

    $('#grabaserial').on('click',function()
    {
        $articulo = $('#artref').val();
        $serial = $('#serial').val();
        $referencia = $('#referencia').val();
        $sucursal = $('#sucursal').val();
        var $token = $('meta[name="csrf-token"]').attr('content');
            $.ajax({
                type : 'post',
                url : '/prodcompra/serial',
                data:{'articulos_id':$articulo , 'serial' : $serial , 'referencia' : $referencia , '_token' : $token , 'sucursal' : $sucursal},

                success:function(data)
                {
                  $('#cargoproducto').trigger('submit');
                }

            });
        });

  }
//FIN COMPRAS --------------------------------------------------------------------------------



//CAJA
$(function(){
  $('#dni').on(function(){
    valor=$(this).val();
      $('#clientes_dni').val(valor);
    })
  });

//VENTAS -------------------------------------------------------------------------------------
  if(jQuery(location).attr('pathname').indexOf('ventas/') > -1 || jQuery(location).attr('pathname').indexOf('validartv/') > -1 || jQuery(location).attr('pathname').indexOf('update/') > -1 )
  {

  $(function (){
      $('#articulos_id').trigger('focus');
    });


  //Valido el numero de articulo si lleva serial

    $('#articulos_id').on('input paste focus',function(e)
    {
      if(e.key === 'ENTER') {
        e.preventDefault();
      }
      $('#nseriev').empty();
        $value=$(this).val();

        $sucursal = $('#sucursal').val();
            $.ajax({
                type : 'get',
                url : '/validartv',
                data:{'id':$value , 'sucursal' : $sucursal},
                success:function(data)
                {
                  if(data === 'NOSERIAL')
                  {
                    $('#resultado').html('<small class="bg-warning text-dark">SIN SERIAL</small>');
                    $('#cargaprod').prop('disabled',false);
                  }
                  else
                  {
                    $('#resultado').html('<small class="bg-success text-light">SERIAL DISPONIBLE</small>');
                    for(let i = 0; i < data.length; i++)
                    {
                      $('#nseriev').append(new Option(data[i], data[i])); // (o el campo que necesites)
                    }
                    $('#cargaprod').prop('disabled',false);
                  }

                }

            });
        });


    $('#cargaprod').on('click', function (e){
      if(e.key === 'ENTER') {
        e.preventDefault();
      }
      if($('#articulos_id').length > 0)
      {
          if ($('#nseriev').val().trim() === '') {

            $('#cargoprodventa').trigger('submit');
            return true;
          }
          else
          {

            $('#cantidad').val('1');
            $('#artref').val($('#articulos_id').val());
            $('#Modalserie').modal({backdrop: 'static', keyboard: false});
            $('#Modalserie').modal('show');

            return false;
          }

      }

    });


     //Actualizo el numero de serial

    $('#updateserial').on('click',function()
    {
      $.ajaxSetup({ headers: { 'csrftoken' : '{{ csrf_token() }}' } });
        $articulo = $('#artref').val();
        $serial = $("#nseriev option:selected").val();
        $tok = $('#ref').val();
        $sucursal = $('#sucursal').val();
        $token = $('meta[name="csrf-token"]').attr('content');
            $.ajax({
                type : 'post',
                url : '/update',
                data:{'art':$articulo , 'serial' : $serial , '_token' : $token ,'tok' : $tok, 'sucursal' : $sucursal},
                success:function(data)
                {

                  $('#cargoprodventa').trigger('submit');

                }

            });
        });
  }
  //FIN VENTAS--------------------------------------------------------------------------------

  //NCREDITOS -----------------------------------------------------
  if(jQuery(location).attr('pathname').indexOf('Nota-de-credito/') > -1 || jQuery(location).attr('pathname').indexOf('validartv/') > -1 || jQuery(location).attr('pathname').indexOf('validanc/') > -1 )
  {

  //Valido si existe la venta

  $('#ncventa').on('keyup',function()
  {
      $('#ncdni').empty();
      $('#nctotal').empty();
      $valor = $(this).val();
          $.ajax({
              type : 'get',
              url : '/validanc',
              data:{'id':$valor },
              success:function(data)
              {
                if(data === 'NOVENTA')
                  {
                    $('#resultado').html('<span class="bg-warning text-dark">NO EXISTE LA VENTA</span>');
                    $('#registranc').prop('disabled',true);
                  }
                  else
                  {
                    $('#resultado').html('<span class="bg-success text-light">VENTA EXISTENTE</span>');
                    for(let i = 0; i < data.length; i++)
                    {
                      $('#ncdni').html(data[i].clientes_dni);
                      $('#nctotal').html(data[i].total);
                      $('#registranc').prop('disabled',false);

                    }
                  }

              }

          });
      });



  }

  //FIN NCREDITOS ------------------------------------------------


  //CAJA
  if(jQuery(location).attr('pathname').indexOf('caja/') > -1 || jQuery(location).attr('pathname').indexOf('getmedio') > -1)
  {

    $(function(){
      $('#cupones').css('display','none');
      $('#referencia').css('display','none');
      $('#efectivo').css('display','none');
      $('#medios option:selected').val('1').trigger('change');
    });

    //Seleccionar medio de pago
      $('#medios').on('change ready',function(){

        $idmedio = $('#medios option:selected').val();
        $('#cupones').css('display','none');
        $('#lote').prop('required',false);
        $('#cupon').prop('required',false);
        $('#cuotas').prop('required',false);
        $('#referencia').css('display','none');
        $('#ref').prop('required',false);
        $('#efectivo').css('display','none');
        $token = $('meta[name="csrf-token"]').attr('content');
            $.ajax({
                type : 'get',
                url : '/caja/getmedio',
                data:{'idmedio': $idmedio , '_token' : $token},

                success:function(data)
                {
                  if(data === 'ERROR')
                  {
                    $('#resultado').html('<small class="bg-warning text-dark">ERROR EN LA BUSQUEDA</small>');
                  }
                  else
                  {
                    for(let i = 0; i < data.length; i++)
                    {
                      if(data[i].tipo === 1)
                      {
                        $('#efectivo').css('display','block');
                      }
                      else if (data[i].tipo === 2)
                      {
                        $('#cupones').css('display','block');
                        $('#lote').prop('required',true);
                        $('#cupon').prop('required',true);
                        $('#cuotas').prop('required',true);
                      }
                      else
                      {
                        $('#referencia').css('display','block');
                        $('#ref').prop('required',true);
                      }
                      const tt = $('#total_imp').val();
                      const tts = $('#total_sin').val();
                      const lista = $('#lista').text();

                      var tot = tt;
                      var tots = tts;
                      var list = lista;

                      var total = tot.replace(/,/g,"");
                      var totalsin = tots.replace(/,/g,"");

                      var interes = data[i].interes;
                      if(interes === '0')
                      {
                          interes = '1';
                      }
                      var montoint = (total*interes)/100;
                      var suma = (parseFloat(total)+montoint);
                      var suma2 = (parseFloat(totalsin)+montoint);
                      var suma3 = (parseFloat(list)+montoint);
                      $('.mnom').html(data[i].nombre);
                      $('.mint').html(data[i].interes);

                      $('#totalmedio').html(suma.toFixed(2));
                      $('#totalsmedio').html(suma2.toFixed(2));
                      if(data[i].tipo >= 2)
                      {
                        $('#lista').html(suma3.toFixed(2));
                      }

                    }

                  }
                }

            });
        });


  }

  //ARTICULOS
  if(jQuery(location).attr('pathname').indexOf('articulos') > -1 )
  {
    $('#contado').on('input', function(){
      if($('#costo').val() === '0' )
      {
        $('#mensajito').text('TIENES QUE RELLENAR EL CAMPO COSTO ANTES!!');
      }
      else
      {
        var costo = $('#costo').val();
        var contado = $('#contado').val();
        var resta = contado - costo;
        var val = (100 * resta) / costo;
        var valor = val.toString().replace(",",".");
        var cuenta = parseFloat(valor);
        $('#markup').val(cuenta.toFixed(2));
      }


    });

    $('#bedit').on('keyup input', function(){
      $id = $('#bedit').val();
      $('#bname').val('');
      $.ajax({
        type : 'get',
        url : '/articulos/busca',
        data:{'id':$id},
        success:function(data)
        {
          $('.tarb > tbody').empty();
          $.each(data, function (i, item) {
            var rows = "<tr>" +
                "<td>" + item.id + "</td>" +
                "<td>" + item.familia.nombre + "</td>" +
                "<td>" + item.rubro.nombre + "</td>" +
                "<td>" + item.marca.nombre + "</td>" +
                "<td>" + item.descripcion + "</td>" +
                "<td>" + item.costo + "</td>" +
                "<td>" + item.markup + "</td>" +
                "<td>" + item.impuesto + "</td>" +
                "<td>" + item.estado + "</td>" +
                "<td> <a href=\"articulos/"+item.id+"/edit\" class=\"btn btn-warning small\" role=\"button\">Editar</a></td>" +
                "<td><button class=\"btn btn-danger small\" role=\"button\" disabled>Eliminar</button></td>" +
                "</tr>";
            $('.tarb > tbody').append(rows);
        });


        }

      });


    });
    $('#bname').on('keyup input', function(){
      $name = $('#bname').val();
      $('#bedit').val('');
      $.ajax({
        type : 'get',
        url : '/articulos/busca',
        data:{'name':$name},
        success:function(data)
        {
          $('.tarb > tbody').empty();
          $.each(data, function (i, item) {
            var rows = "<tr>" +
                "<td>" + item.id + "</td>" +
                "<td>" + item.familia.nombre + "</td>" +
                "<td>" + item.rubro.nombre + "</td>" +
                "<td>" + item.marca.nombre + "</td>" +
                "<td>" + item.descripcion + "</td>" +
                "<td>" + item.costo + "</td>" +
                "<td>" + item.markup + "</td>" +
                "<td>" + item.impuesto + "</td>" +
                "<td>" + item.estado + "</td>" +
                "<td> <a href=\"articulos/"+item.id+"/edit\" class=\"btn btn-warning small\" role=\"button\">Editar</a></td>" +
                "<td><button class=\"btn btn-danger small\" role=\"button\" disabled>Eliminar</button></td>" +
                "</tr>";
            $('.tarb > tbody').append(rows);
        });


        }

      });


    });

  }

